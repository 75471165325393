<template>
  <v-autocomplete
    class="font-normal header-input"
    v-bind="$attrs"
    v-on="$listeners"
    v-model="currentValue"
    :items="items"
    :height="height"
    flat
    background-color="headerInputBg"
    append-icon="mdi-chevron-down"
    solo
    hide-details
  >
    <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope"/>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'SelectSearchHeader',
  inheritAttrs: false,
  props: {
    value: {
      required: true
    },
    items: {
      type: Array,
      default: () => []
    },
    height: {
      type: String | Number,
      default: 46
    },
  },
  data: () => ({
    focusSelect: false
  }),
  computed: {
    currentValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
}
</script>