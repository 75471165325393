<template>
  <v-row class="login-row" no-gutters>
    <v-col class="d-flex justify-center align-center" cols="12" md="5">
      <v-card
        class="emptyLayoutBg pr-sm-20 pr-md-0 rounded-0"
        flat
        :max-width="checkSm ? 450 : 280"
      >
        <div
          class="text-uppercase pb-16 pb-sm-33 mr-sm-n2 loginTitle--text"
          :class="checkSm ? 'font-large-login text-right' : 'font-row-title'"
        >login as</div>
        <v-row no-gutters>
          <v-col
            class="d-flex flex-column justify-center pb-4 pb-sm-10"
            :class="{ 'align-end': checkSm }"
            cols="12"
            sm="4"
          >
            <span class="font-normal loginColor--text">Managers</span>
          </v-col>

          <v-col class="pb-10 pl-sm-11" cols="12" sm="8">
            <SelectSearchHeader
              class="login-form-input gray-border"
              v-model="accountId"
              :items="accounts"
              item-value="slug"
              item-text="email"
              height="48"
              :menu-props="{ nudgeTop: -5, contentClass: 'select-search-header' }"
              :loading="loading"
            />
          </v-col>

          <v-col class="pt-10 pl-12 d-flex justify-end align-center" cols="12">
            <div class="font-xs textLink--text pr-10">
              <TextLink @click="logOut">Log out</TextLink>
            </div>

            <ActionBtnForDarkBg
              type="submit"
              @click.prevent="submitForm"
              :loading="isLoading"
            >
              Login
            </ActionBtnForDarkBg>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12" md="7">
      <div
        class="d-flex flex-column align-center justify-center"
        :class="checkMd ? 'image-right' : 'image-bottom'"
      >
        <div class="my-auto"></div>
        <v-icon class="mt-auto" :size="checkSm ? 325 : 180">$logoIcon</v-icon>
        <div class="d-flex my-auto pb-10">
          <v-icon class="mr-10" @click="goToLinkedin">$linkedinkIcon</v-icon>
          <v-icon @click="goToFacebook">$facebookIcon</v-icon>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import SelectSearchHeader from '@/components/select/SelectSearchHeader'
import FormField from '@/components/inputs/FormField'
import ActionBtnForDarkBg from '@/components/buttons/ActionBtnForDarkBg'
import TextLink from '@/components/links/TextLink'

import { mapState, mapMutations, mapActions } from 'vuex'
import { Types as authTypes } from '@/store/modules/auth'

import { getAllAccounts, loginAs } from '@/api/select-accounts'
import { setAccessToken, getAccountId, setAccountId, setUserNameAndEmailForCrispChat } from '@/helper/auth-helper'

export default {
  name: 'ManagerAccounts',
  components: { SelectSearchHeader, FormField, ActionBtnForDarkBg, TextLink },
  data: () => ({
    loading: false,
    isLoading: false,
    accountId: null,
    accounts: [],
  }),
  computed: {
    ...mapState({
      role: state => state.auth.role,
      pbx: state => state.auth.pbx,
      subscription: state => state.auth.subscription,
    }),
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
    checkSm() {
      return this.$vuetify.breakpoint.width > 599
    },
  },
  mounted() {
    this.loadAllAccounts()
  },
  methods: {
    ...mapMutations({
      setAccounts: authTypes.mutations.SET_ACCOUNTS,
      setUser: authTypes.mutations.SET_USER,
    }),
    ...mapActions({
      logOut: authTypes.actions.LOG_OUT,
      checkUserSubAccount: authTypes.actions.CHECK_USER_SUB_ACCOUNT,
    }),
    async loadAllAccounts() {
      this.loading = true

      const { success, payload, message } = await getAllAccounts()

      if (success) {
        this.accounts = payload
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loading = false
    },
    async submitForm() {
      if (!this.accountId) {
        return
      }

      this.isLoading = true

      const { success, payload, message } = await loginAs(this.accountId)

      if (success) {
        setAccessToken(payload['access_token'])
        this.setAccounts(payload.accounts)
        this.setUser(payload.user)
        this.checkAccount(payload.accounts)
        setUserNameAndEmailForCrispChat({
          name: payload.user['first_name'],
          email: payload.user.email
        })
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.isLoading = false
    },
    checkAccount(accounts) {
      const currentAccount = accounts.find(account => account.slug === getAccountId())
      if (!currentAccount) {
        setAccountId(accounts[0].slug)
      }

      this.checkUserSubAccount(getAccountId())
    },
    goToFacebook() {
      window.open('https://www.facebook.com/Dialics-109364704630086', '_blank')
    },
    goToLinkedin() {
      window.open('https://www.linkedin.com/company/dialics', '_blank')
    },
  },
  watch: {
    role: {
      immediate: true,
      handler(role) {
        if (!!role) {
          if (role === 'select_accounts') {
            return
          }

          if (!this.pbx) {
            return this.$router.push({ name: 'ConnectPbx' })
          }

          if (!this.subscription) {
            return this.$router.push({ name: 'Subscription' })
          }

          if (role === 'Manager') {
            return this.$router.push({ name: 'Dashboard' })
          }

          return this.$router.push({ name: 'Reporting' })
        }
      },
    },
  }
}
</script>